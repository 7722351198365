<script>
import {alertIfError, httpGet, httpPost} from "@/utils/requestUtils";
export default {
  data() {
    return {
      loading: false,
      globalReaders: [],
      globalWriters: [],
      globalReadersSearchQuery: ``,
      globalWritersSearchQuery: ``,
      newGlobalReaderEmail: ``,
      newGlobalWriterEmail: ``,
    };
  },
  mounted() {
    this.fetchGlobalPermissions();
  },
  methods: {
    fetchGlobalPermissions() {
      this.loading = true;
      httpGet(process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_GET_GLOBAL_PERMISSIONS_URL)
          .then(response => {
            if (response) {
              if (response.code === 200) {
                this.globalReaders = response.data.permissions?.read;
                this.globalWriters = response.data.permissions?.write;
              } else alert(response.message);
            } else alert(`An unknown error occurred`);
          })
          .catch(error => console.error(`Error fetching global permissions: `, error))
          .finally(() => this.loading = false);
    },
    addGlobalReader(email) {
      this.loading = true;
      this.globalReadersSearchQuery = ``;
      this.newGlobalReaderEmail = ``;
      httpPost(
          process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_DO_ADD_GLOBAL_READER_URL,
          {
            email: email
          }
      )
          .then(alertIfError)
          .catch(error => console.error(`Error adding global reader: `, error))
          .finally(() => this.fetchGlobalPermissions())
    },
    removeGlobalReader(email) {
      this.loading = true;
      this.globalReadersSearchQuery = ``;
      httpPost(
          process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_DO_REMOVE_GLOBAL_READER_URL,
          {
            email: email
          }
      )
          .then(alertIfError)
          .catch(error => console.error(`Error removing global reader: `, error))
          .finally(() => this.fetchGlobalPermissions())
    },
    addGlobalWriter(email) {
      this.loading = true;
      this.globalWritersSearchQuery = ``;
      this.newGlobalWriterEmail = ``;
      httpPost(
          process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_DO_ADD_GLOBAL_WRITER_URL,
          {
            email: email
          }
      )
          .then(alertIfError)
          .catch(error => console.error(`Error adding global writer: `, error))
          .finally(() => this.fetchGlobalPermissions())
    },
    removeGlobalWriter(email) {
      this.loading = true;
      this.globalWritersSearchQuery = ``;
      httpPost(
          process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_DO_REMOVE_GLOBAL_WRITER_URL,
          {
            email: email
          }
      )
          .then(alertIfError)
          .catch(error => console.error(`Error removing global writer: `, error))
          .finally(() => this.fetchGlobalPermissions())
    },
  },
  computed: {
    filterGlobalReaders() {
      return this.globalReaders.filter(globalReader =>
          globalReader.toLowerCase().includes(this.globalReadersSearchQuery.toLowerCase())
      );
    },
    filterGlobalWriters() {
      return this.globalWriters.filter(globalWriter =>
          globalWriter.toLowerCase().includes(this.globalWritersSearchQuery.toLowerCase())
      );
    }
  }
};
</script>

<template>
  <div class="bold h1 mt-4 mx-4 mb-2">Global Permissions</div>
  <div class="card-container d-flex flex-wrap px-2">
    <div class="col-lg-6 col-12 p-2">
      <div class="border-0 card rounded-16 shadow">
        <div class="card-body m-2">
          <div class="bold h1 mb-3">FSS Global Readers</div>
          <div class="container-fluid">
            <div class="mb-4 row">
              <div class="col-6 pe-1 ps-0">
                <div class="mb-1">
                  <div class="bold">Search FSS Global Readers</div>
                </div>
                <input class="form-control" type="text" v-model="globalReadersSearchQuery"
                       placeholder="Search email address..."/>
              </div>
              <div class="col-6 pe-0 ps-1">
                <div class="mb-1">
                  <div class="bold">Add FSS Global Reader</div>
                </div>
                <div class="input-group">
                  <input class="form-control" type="text" v-model="newGlobalReaderEmail"
                         placeholder="Add by email address..."
                         @keyup.enter="addGlobalReader(newGlobalReaderEmail)"/>
                  <button class="btn btn-accent" @click="addGlobalReader(newGlobalReaderEmail)">
                    <span>Add &rsaquo;</span>
                  </button>
                </div>
              </div>
            </div>
            <div v-if="loading" class="loading-spinner-large">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div v-else>
              <div class="bold mb-1 row">
                <div class="col-6">Email Address</div>
              </div>
              <div v-for="globalReader in filterGlobalReaders" :key="globalReader"
                   class="border-bottom py-2 row row-hover">
                <a class="col-6 clickable" :href="`mailto:${globalReader}`">{{ globalReader }}</a>
                <a class="col-6 clickable text-end" @click="removeGlobalReader(globalReader)">Remove</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-12 p-2">
      <div class="border-0 card rounded-16 shadow">
        <div class="card-body m-2">
          <div class="bold h1 mb-3">FSS Global Writers</div>
          <div class="container-fluid">
            <div class="mb-4 row">
              <div class="col-6 pe-1 ps-0">
                <div class="mb-1">
                  <div class="bold">Search FSS Global Writers</div>
                </div>
                <input class="form-control" type="text" v-model="globalWritersSearchQuery"
                       placeholder="Search email address..."/>
              </div>
              <div class="col-6 pe-0 ps-1">
                <div class="mb-1">
                  <div class="bold">Add FSS Global Writer</div>
                </div>
                <div class="input-group">
                  <input class="form-control" type="text" v-model="newGlobalWriterEmail"
                         placeholder="Add by email address..."
                         @keyup.enter="addGlobalWriter(newGlobalWriterEmail)"/>
                  <button class="btn btn-accent" @click="addGlobalWriter(newGlobalWriterEmail)">
                    <span>Add &rsaquo;</span>
                  </button>
                </div>
              </div>
            </div>
            <div v-if="loading" class="loading-spinner-large">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div v-else>
              <div class="bold mb-1 row">
                <div class="col-6">Email Address</div>
              </div>
              <div v-for="globalWriter in filterGlobalWriters" :key="globalWriter"
                   class="border-bottom py-2 row row-hover">
                <a class="col-6 clickable" :href="`mailto:${globalWriter}`">{{ globalWriter }}</a>
                <a class="col-6 clickable text-end" @click="removeGlobalWriter(globalWriter)">Remove</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.btn-accent {
  background: var(--accent);
  color: var(--bs-white);
}

.btn-accent:hover {
  background: var(--accent);
  color: var(--bs-white);
}
</style>
