<template>
  <Navbar v-if="authenticated"/>
  <router-view/>
</template>

<script>
import Navbar from "./components/NavBar.vue";
import {authStore} from "./store/authStore";
import {computed} from "vue";


export default {
  name: `App`,
  components: {
    Navbar
  },
  setup() {
    const authenticated = computed(() => authStore.authenticated);
    return {authenticated};
  }
};
</script>

<style>
</style>
