<script>
import {httpGet} from "@/utils/requestUtils";

export default {
  data() {
    return {
      loading: false,
      projects: [],
      searchQuery: ``
    };
  },
  mounted() {
    this.fetchProjects();
  },
  methods: {
    fetchProjects() {
      this.loading = true;
      httpGet(process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_GET_ALL_PROJECTS_URL)
          .then(response => {
            if (response) {
              if (response.code === 200) {
                this.projects = response.data.projects;
              } else alert(response.message);
            } else alert(`An unknown error occurred`);
          })
          .catch(error => console.error(`Error fetching projects: `, error))
          .finally(() => this.loading = false);
    }
  },
  computed: {
    filteredProjects() {
      return this.projects.filter(project =>
          project.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    }
  }
};
</script>

<template>
  <div class="bold h1 mt-4 mx-4 mb-2">Projects</div>
  <div class="card-container d-flex flex-wrap px-2">
    <div class="col-12 p-2">
      <div class="border-0 card rounded-16 shadow">
        <div class="card-body m-2">
          <div class="bold h1 mb-3">FSS Projects</div>
          <div class="container-fluid">
            <div class="mb-4 row">
              <div class="col-12 px-0">
                <div class="mb-1">
                  <div class="bold">Search FSS Projects</div>
                </div>
                <input class="form-control" type="text" v-model="searchQuery" placeholder="Search projects..."/>
              </div>
            </div>
            <div v-if="loading" class="loading-spinner-large">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div v-else>
              <div class="bold mb-1 row">
                <div>FSS Project Name</div>
              </div>
              <div v-for="project in filteredProjects" :key="project.id" class="border-bottom py-2 row row-hover">
                <router-link class="clickable" :to="{ name: 'project', params: { project: project } }">{{
                    project
                  }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>