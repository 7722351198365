<script>
import {alertIfError, httpGet, httpPost} from "@/utils/requestUtils";
import {formatAccessLevel} from "@/utils/formattingUtils";
export default {
  props: {
    project: String
  },
  data() {
    return {
      loading: false,
      allGroups: [],
      groupPermissions: [],
      localPermissions: [],
      groupPermissionsSearchQuery: ``,
      localPermissionsSearchQuery: ``,
      newGroupPermissionName: ``,
      newGroupPermissionNameIsFocused: false,
      newLocalPermissionEmail: ``,
      newGroupPermissionType: ``,
      newLocalPermissionType: ``,
      testUserPermissionsEmail: ``,
      testUserDisplayEmail: `No user selected`,
      testUserGlobalPermissions: `none`,
      testUserGroupPermissions: {},
      testUserLocalPermissions: `none`
    };
  },
  mounted() {
    this.fetchProjectPermissions();
  },
  methods: {
    formatAccessLevel,
    fetchProjectPermissions() {
      this.loading = true;
      httpGet(
          process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_GET_PROJECT_PERMISSIONS_URL,
          {
            project: this.project
          }
      )
          .then(response => {
            if (response) {
              if (response.code === 200) {
                this.groupPermissions = response.data.group;
                this.localPermissions = response.data.local;
              } else alert(response.message);
            } else alert(`An unknown error occurred`);
          })
          .catch(error => console.error(`Error fetching project '${this.project}' permissions: `, error))
          .finally(() => this.loading = false);
      httpGet(process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_GET_PERMISSION_GROUPS_URL)
          .then(response => {
            if (response) {
              if (response.code === 200) {
                this.allGroups = response.data.groups;
              } else alert(response.message);
            } else alert(`An unknown error occurred`);
          })
          .catch(error => console.error(`Error fetching permission groups: `, error))
          .finally(() => this.loading = false);
    },
    addProjectGroupPermission(group, permission) {
      this.loading = true;
      this.groupPermissionsSearchQuery = ``;
      this.newGroupPermissionName = ``;
      httpPost(
          process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_DO_ADD_PROJECT_GROUP_PERMISSION_URL,
          {
            group: group,
            project: this.project,
            permission: permission
          }
      )
          .then(alertIfError)
          .catch(error => console.error(`Error adding '${permission}' permission for group '${group}' on project '${this.project}': `, error))
          .finally(() => this.fetchProjectPermissions())
    },
    setNewProjectGroupPermission(group) {
      this.newGroupPermissionName = group;
      this.$refs.newGroupPermissionNameInput.blur();
    },
    removeProjectGroupPermission(group) {
      this.loading = true;
      this.groupPermissionsSearchQuery = ``;
      httpPost(
          process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_DO_REMOVE_PROJECT_GROUP_PERMISSION_URL,
          {
            group: group,
            project: this.project
          }
      )
          .then(alertIfError)
          .catch(error => console.error(`Error removing permissions for group '${group}' on project '${this.project}': `, error))
          .finally(() => this.fetchProjectPermissions())
    },
    addProjectLocalPermission(email, permission) {
      this.loading = true;
      this.localPermissionsSearchQuery = ``;
      this.newLocalPermissionEmail = ``;
      httpPost(
          process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_DO_ADD_PROJECT_LOCAL_PERMISSION_URL,
          {
            email: email,
            project: this.project,
            permission: permission
          }
      )
          .then(alertIfError)
          .catch(error => console.error(`Error adding '${permission}' permission for '${email}' on project '${this.project}': `, error))
          .finally(() => this.fetchProjectPermissions())
    },
    removeProjectLocalPermission(email) {
      this.loading = true;
      this.localPermissionsSearchQuery = ``;
      httpPost(
          process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_DO_REMOVE_PROJECT_LOCAL_PERMISSION_URL,
          {
            email: email,
            project: this.project
          }
      )
          .then(alertIfError)
          .catch(error => console.error(`Error removing permissions for '${email}' on project '${this.project}': `, error))
          .finally(() => this.fetchProjectPermissions())
    },
    testUserPermissions(email) {
      this.loading = true;
      this.testUserDisplayEmail = this.testUserPermissionsEmail;
      httpGet(
          process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_GET_USER_PERMISSIONS_ON_PROJECT_URL,
          {
            email: email,
            project: this.project
          }
      )
          .then(response => {
            if (response) {
              if (response.code === 200) {
                this.testUserGlobalPermissions = response.data.global || `none`;
                this.testUserGroupPermissions = response.data.group;
                this.testUserLocalPermissions = response.data.local || `none`;
              } else alert(response.message);
            } else alert(`An unknown error occurred`);
          })
          .catch(error => console.error(`Error fetching project '${this.project}' permissions: `, error))
          .finally(() => this.loading = false);
    }
  },
  computed: {
    filterGroupPermissions() {
      return Object.keys(this.groupPermissions).filter(groupPermission =>
          groupPermission.toLowerCase().includes(this.groupPermissionsSearchQuery.toLowerCase())
      );
    },
    filterLocalPermissions() {
      return Object.keys(this.localPermissions).filter(localPermission =>
          localPermission.toLowerCase().includes(this.localPermissionsSearchQuery.toLowerCase())
      );
    },
    filterAllGroups() {
      return this.allGroups.filter(group => group.toLowerCase().includes(this.newGroupPermissionName.toLowerCase()));
    }
  }
};
</script>

<template>
  <div class="bold h1 mt-4 mx-4 mb-2">Project: {{ project }}</div>
  <div class="card-container d-flex flex-wrap px-2">
    <div class="col-lg-6 col-12 p-2">
      <div class="border-0 card rounded-16 shadow">
        <div class="card-body m-2">
          <div class="bold h1 mb-3">Group Permissions</div>
          <div class="container-fluid">
            <div class="mb-4 row">
              <div class="col-6 pe-1 ps-0">
                <div class="mb-1">
                  <div class="bold">Search Group Permissions</div>
                </div>
                <input class="form-control" type="text" v-model="groupPermissionsSearchQuery"
                       placeholder="Search group name..."/>
              </div>
              <div class="col-6 pe-0 ps-1">
                <div class="mb-1">
                  <div class="bold">Add Group Permission</div>
                </div>
                <div class="input-group">
                  <input class="form-control" type="text" v-model="newGroupPermissionName"
                         placeholder="Add by group name..."
                         ref="newGroupPermissionNameInput"
                         @focus="newGroupPermissionNameIsFocused = true"
                         @blur="newGroupPermissionNameIsFocused = false"
                         @keyup.enter="addProjectGroupPermission(newGroupPermissionName)"/>
                  <select class="form-select" v-model="newGroupPermissionType">
                    <option value="" disabled>Select access level...</option>
                    <option value="read">Read</option>
                    <option value="write">Write</option>
                  </select>
                  <button class="btn btn-accent"
                          @click="addProjectGroupPermission(newGroupPermissionName, newGroupPermissionType)">
                    <span>Add &rsaquo;</span>
                  </button>
                </div>


                <div class="position-relative" v-if="newGroupPermissionNameIsFocused && filterAllGroups.length">
                  <ul class="clickable dropdown list-group  position-absolute shadow w-100">
                    <li class="list-group-item" v-for="group in filterAllGroups" :key="group"
                        @mousedown.prevent="setNewProjectGroupPermission(group)">
                      {{ group }}
                    </li>
                  </ul>
                </div>


              </div>
            </div>
            <div v-if="loading" class="loading-spinner-large">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div v-else>
              <div class="bold mb-1 row">
                <div class="col-4">FSS Permission Group Name</div>
                <div class="col-4">Access Level</div>
              </div>
              <div v-for="group in filterGroupPermissions" :key="group"
                   class="border-bottom py-2 row row-hover">
                <router-link class="col-4 clickable" :to="{ name: 'permissionGroup', params: { group: group } }">
                  {{ group }}
                </router-link>
                <div class="col-4 fst-italic">{{ formatAccessLevel(this.groupPermissions[group]) }}</div>
                <a class="col-4 clickable text-end" @click="removeProjectGroupPermission(group)">Remove</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-12 p-2">
      <div class="border-0 card rounded-16 shadow">
        <div class="card-body m-2">
          <div class="bold h1 mb-3">Local Permissions</div>
          <div class="container-fluid">
            <div class="mb-4 row">
              <div class="col-6 pe-1 ps-0">
                <div class="mb-1">
                  <div class="bold">Search Local Permissions</div>
                </div>
                <input class="form-control" type="text" v-model="localPermissionsSearchQuery"
                       placeholder="Search email address..."/>
              </div>
              <div class="col-6 pe-0 ps-1">
                <div class="mb-1">
                  <div class="bold">Add Local Permission</div>
                </div>
                <div class="input-group">
                  <input class="form-control" type="text" v-model="newLocalPermissionEmail"
                         placeholder="Add by email address..."
                         @keyup.enter="addProjectLocalPermission(newLocalPermissionEmail)"/>
                  <select class="form-select" v-model="newLocalPermissionType">
                    <option value="" disabled>Select access level...</option>
                    <option value="read">Read</option>
                    <option value="write">Write</option>
                  </select>
                  <button class="btn btn-accent"
                          @click="addProjectLocalPermission(newLocalPermissionEmail, newLocalPermissionType)">
                    <span>Add &rsaquo;</span>
                  </button>
                </div>
              </div>
            </div>
            <div v-if="loading" class="loading-spinner-large">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div v-else>
              <div class="bold mb-1 row">
                <div class="col-4">Email Address</div>
                <div class="col-4">Access Level</div>
              </div>
              <div v-for="email in filterLocalPermissions" :key="email"
                   class="border-bottom py-2 row row-hover">
                <router-link class="col-4 clickable" :to="{ name: 'project', params: { project: email } }">
                  {{ email }}
                </router-link>
                <div class="col-4 fst-italic">{{ formatAccessLevel(this.localPermissions[email]) }}</div>
                <a class="col-4 clickable text-end" @click="removeProjectLocalPermission(email)">Remove</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-12 p-2">
      <div class="border-0 card rounded-16 shadow">
        <div class="card-body m-2">
          <div class="bold h1 mb-3">Test User Permissions</div>
          <div class="container-fluid">
            <div class="mb-4 row">
              <div class="col-12 px-0">
                <div class="mb-1">
                  <div class="bold">Email address</div>
                </div>
                <div class="input-group">
                  <input class="form-control" type="text" v-model="testUserPermissionsEmail"
                         placeholder="Email address..."
                         @keyup.enter="testUserPermissions(testUserPermissionsEmail)"/>
                  <button class="btn btn-accent" @click="testUserPermissions(testUserPermissionsEmail)">
                    <span>Test &rsaquo;</span>
                  </button>
                </div>
              </div>
            </div>
            <div v-if="loading" class="loading-spinner-large">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div v-else>
              <div class="mb-2">
                <div class="bold">Email address:</div>
                {{ testUserDisplayEmail }}
              </div>
              <div>
                <div class="bold">Global Permissions</div>
                <div>
                  <ul class="mb-0">
                    <li class="fst-italic">{{ formatAccessLevel(this.testUserGlobalPermissions) }}</li>
                  </ul>
                </div>
              </div>
              <div>
                <div class="bold">Group Permissions</div>
                <div>
                  <ul v-if="Object.keys(testUserGroupPermissions).length === 0" class="mb-0">
                    <li class="fst-italic">None</li>
                  </ul>
                  <ul v-else class="mb-0">
                    <li v-for="group in Object.keys(testUserGroupPermissions)" :key="group">
                      <span class="fst-italic">{{ formatAccessLevel(testUserGroupPermissions[group]) }}</span>
                      (from <router-link class="col-4 clickable" :to="{ name: 'permissionGroup', params: { group: group } }">
                        {{ group }}
                      </router-link>)
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <div class="bold">Local Permissions</div>
                <div>
                  <ul class="mb-0">
                    <li class="fst-italic">{{ formatAccessLevel(this.testUserLocalPermissions) }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.btn-accent {
  background: var(--accent);
  color: var(--bs-white);
}

.btn-accent:hover {
  background: var(--accent);
  color: var(--bs-white);
}

.dropdown {
  top: -1px;
}
</style>