<script>
export default {
  name: "NavBar",
  data() {
    return {
      logoutUrl: process.env.VUE_APP_AUTH_HOST + process.env.VUE_APP_AUTH_LOGOUT_URL,
    };
  },
};
</script>

<template>
  <div class="nav-container p-3 shadow"> <!-- Parent container for centering -->
    <div class="navbar-logo mb-3">
      <img src="/data/rugged-logo-transparent.png" alt="Logo">
    </div>
    <nav class="navbar navbar-expand-lg">
      <div class="container-fluid">
        <button class="navbar-toggler mb-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-center" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'projects' }">Project Permissions</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'permissionGroups' }">Permission Groups</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'globalPermissions' }">Global Permissions</router-link>
            </li>
            <li class="nav-item">
              <a class="nav-link" :href="logoutUrl">Log Out</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<style scoped>
.nav-container {
  background-color: var(--bs-white);
}
.navbar-logo {
  display: flex;
  justify-content: center;
}
.navbar-logo img {
  width: 128px;
  height: auto;
}
.navbar {
  background-color: var(--bs-white);
  padding: 0;
}
.nav-item {
  padding: 0rem 1rem;
}
.nav-link {
  color: var(--bs-gray);
  padding: 0;
  text-decoration: none;
}
.nav-link:hover {
  color: var(--accent);
  text-decoration: none;
}
nav a {
  color: var(--accent);
  border-bottom: 2px solid transparent;
}
nav a.router-link-exact-active {
  color: var(--accent);
  border-bottom: 2px solid var(--accent);
}
</style>
