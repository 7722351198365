<script>
import {alertIfError, httpGet, httpPost} from "@/utils/requestUtils";

export default {
  data() {
    return {
      loading: false,
      permissionGroups: [],
      permissionGroupSearchQuery: ``,
      newPermissionGroupName: ``
    };
  },
  mounted() {
    this.fetchPermissionGroups();
  },
  methods: {
    fetchPermissionGroups() {
      this.loading = true;
      httpGet(process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_GET_PERMISSION_GROUPS_URL)
          .then(response => {
            if (response) {
              if (response.code === 200) {
                this.permissionGroups = response.data.groups;
              } else alert(response.message);
            } else alert(`An unknown error occurred`);
          })
          .catch(error => console.error(`Error fetching permission groups: `, error))
          .finally(() => this.loading = false);
    },
    createPermissionGroup(group) {
      this.loading = true;
      this.newPermissionGroupName = ``;
      httpPost(
          process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_DO_NEW_PERMISSION_GROUP_URL,
          {
            group: group
          }
      )
          .then(alertIfError)
          .catch(error => console.error(`Error creating permission group: `, error))
          .finally(() => this.fetchPermissionGroups())
    }
  },
  computed: {
    filteredPermissionGroups() {
      return this.permissionGroups.filter(permissionGroup =>
          permissionGroup.toLowerCase().includes(this.permissionGroupSearchQuery.toLowerCase())
      );
    }
  }
};
</script>

<template>
  <div class="bold h1 mt-4 mx-4 mb-2">Permission Groups</div>
  <div class="card-container d-flex flex-wrap px-2">
    <div class="col-12 p-2">
      <div class="border-0 card rounded-16 shadow">
        <div class="card-body m-2">
          <div class="bold h1 mb-3">FSS Permission Groups</div>
          <div class="container-fluid">
            <div class="mb-4 row">
              <div class="col-6 pe-1 ps-0">
                <div class="mb-1">
                  <div class="bold">Search FSS Permission Groups</div>
                </div>
                <input class="form-control" type="text" v-model="permissionGroupSearchQuery"
                       placeholder="Search groups..."/>
              </div>
              <div class="col-6 pe-0 ps-1">
                <div class="mb-1">
                  <div class="bold">Create FSS Permission Group</div>
                </div>
                <div class="input-group">
                  <input class="form-control" type="text" v-model="newPermissionGroupName"
                         placeholder="New group name..."
                         @keyup.enter="createPermissionGroup(newPermissionGroupName)"/>
                  <button class="btn btn-accent" @click="createPermissionGroup(newPermissionGroupName)">
                    <span>Create &rsaquo;</span>
                  </button>
                </div>
              </div>
            </div>
            <div v-if="loading" class="loading-spinner-large">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div v-else>
              <div class="bold mb-1 row">
                <div>FSS Permission Group Name</div>
              </div>
              <div v-for="permissionGroup in filteredPermissionGroups" :key="permissionGroup.id"
                   class="border-bottom py-2 row row-hover">
                <router-link class="clickable" :to="{ name: 'permissionGroup', params: { group: permissionGroup } }">
                  {{ permissionGroup }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.btn-accent {
  background: var(--accent);
  color: var(--bs-white);
}

.btn-accent:hover {
  background: var(--accent);
  color: var(--bs-white);
}
</style>