<script>
import {alertIfError, httpGet, httpPost} from "@/utils/requestUtils";
import {formatAccessLevel} from "@/utils/formattingUtils";
export default {
  props: {
    group: String
  },
  data() {
    return {
      loading: false,
      loading2: false,
      allProjects: [],
      members: [],
      projectPermissions: [],
      membersSearchQuery: ``,
      projectPermissionsSearchQuery: ``,
      newMemberEmail: ``,
      newProjectPermissionName: ``,
      newProjectPermissionNameIsFocused: false,
      newProjectPermissionType: ``,
    };
  },
  mounted() {
    this.fetchPermissionGroup();
  },
  methods: {
    formatAccessLevel,
    fetchPermissionGroup() {
      this.loading = true;
      this.loading2 = true;
      httpGet(
          process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_GET_PERMISSION_GROUP_URL,
          {
            group: this.group
          }
      )
          .then(response => {
            if (response) {
              if (response.code === 200) {
                this.projectPermissions = response.data.projects;
                this.members = response.data.members;
              } else alert(response.message);
            } else alert(`An unknown error occurred`);
          })
          .catch(error => console.error(`Error fetching permission group '${this.group}': `, error))
          .finally(() => this.loading = false);
      httpGet(process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_GET_ALL_PROJECTS_URL)
          .then(response => {
            if (response) {
              if (response.code === 200) {
                this.allProjects = response.data.projects;
              } else alert(response.message);
            } else alert(`An unknown error occurred`);
          })
          .catch(error => console.error(`Error fetching projects: `, error))
          .finally(() => this.loading2 = false);
    },
    addMember(email) {
      this.loading = true;
      this.membersSearchQuery = ``;
      this.newMemberEmail = ``;
      httpPost(
          process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_DO_ADD_TO_PERMISSION_GROUP_URL,
          {
            email: email,
            group: this.group
          }
      )
          .then(alertIfError)
          .catch(error => console.error(`Error adding member to permission group '${this.group}': `, error))
          .finally(() => this.fetchPermissionGroup())
    },
    removeMember(email) {
      this.loading = true;
      this.membersSearchQuery = ``;
      httpPost(
          process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_DO_REMOVE_FROM_PERMISSION_GROUP_URL,
          {
            email: email,
            group: this.group
          }
      )
          .then(alertIfError)
          .catch(error => console.error(`Error removing member from permission group '${this.group}': `, error))
          .finally(() => this.fetchPermissionGroup())
    },
    addProjectPermission(project, permission) {
      this.loading = true;
      this.projectPermissionsSearchQuery = ``;
      this.newProjectPermissionName = ``;
      httpPost(
          process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_DO_ADD_PROJECT_GROUP_PERMISSION_URL,
          {
            group: this.group,
            project: project,
            permission: permission
          }
      )
          .then(alertIfError)
          .catch(error => console.error(`Error adding '${permission}' permission for group '${this.group}' on project '${project}': `, error))
          .finally(() => this.fetchPermissionGroup())
    },
    setNewProjectPermissionName(project) {
      this.newProjectPermissionName = project;
      this.$refs.newProjectPermissionNameInput.blur();
    },
    removeProjectPermission(project) {
      this.loading = true;
      this.projectPermissionsSearchQuery = ``;
      httpPost(
          process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_DO_REMOVE_PROJECT_GROUP_PERMISSION_URL,
          {
            group: this.group,
            project: project
          }
      )
          .then(alertIfError)
          .catch(error => console.error(`Error removing permissions for group '${this.group}' on project '${project}': `, error))
          .finally(() => this.fetchPermissionGroup())
    },
  },
  computed: {
    filterMembers() {
      return this.members.filter(member =>
          member.toLowerCase().includes(this.membersSearchQuery.toLowerCase())
      );
    },
    filterProjectPermissions() {
      return Object.keys(this.projectPermissions).filter(project =>
          project.toLowerCase().includes(this.projectPermissionsSearchQuery.toLowerCase())
      );
    },
    filterAllProjects() {
      return this.allProjects.filter(group => group.toLowerCase().includes(this.newProjectPermissionName.toLowerCase()));
    }
  }
};
</script>

<template>
  <div class="bold h1 mt-4 mx-4 mb-2">Permission Group: {{ group }}</div>
  <div class="card-container d-flex flex-wrap px-2">
    <div class="col-lg-6 col-12 p-2">
      <div class="border-0 card rounded-16 shadow">
        <div class="card-body m-2">
          <div class="bold h1 mb-3">Members</div>
          <div class="container-fluid">
            <div class="mb-4 row">
              <div class="col-6 pe-1 ps-0">
                <div class="mb-1">
                  <div class="bold">Search Members</div>
                </div>
                <input class="form-control" type="text" v-model="membersSearchQuery"
                       placeholder="Search email address..."/>
              </div>
              <div class="col-6 pe-0 ps-1">
                <div class="mb-1">
                  <div class="bold">Add Member</div>
                </div>
                <div class="input-group">
                  <input class="form-control" type="text" v-model="newMemberEmail"
                         placeholder="Add by email address..."
                         @keyup.enter="addMember(newMemberEmail)"/>
                  <button class="btn btn-accent" @click="addMember(newMemberEmail)">
                    <span>Add &rsaquo;</span>
                  </button>
                </div>
              </div>
            </div>
            <div v-if="loading || loading2" class="loading-spinner-large">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div v-else>
              <div class="bold mb-1 row">
                <div class="col-6">Email Address</div>
              </div>
              <div v-for="email in filterMembers" :key="email"
                   class="border-bottom py-2 row row-hover">
                <a class="col-6 clickable" :href="`mailto:${email}`">{{ email }}</a>
                <a class="col-6 clickable text-end" @click="removeMember(email)">Remove</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-12 p-2">
      <div class="border-0 card rounded-16 shadow">
        <div class="card-body m-2">
          <div class="bold h1 mb-3">Associated FSS Projects</div>
          <div class="container-fluid">
            <div class="mb-4 row">
              <div class="col-6 pe-1 ps-0">
                <div class="mb-1">
                  <div class="bold">Search FSS Projects</div>
                </div>
                <input class="form-control" type="text" v-model="projectPermissionsSearchQuery"
                       placeholder="Search project name..."/>
              </div>
              <div class="col-6 pe-0 ps-1">
                <div class="mb-1">
                  <div class="bold">Add FSS Project</div>
                </div>
                <div class="input-group">
                  <input class="form-control" type="text" v-model="newProjectPermissionName"
                         placeholder="Add by project name..."
                         ref="newProjectPermissionNameInput"
                         @focus="newProjectPermissionNameIsFocused = true"
                         @blur="newProjectPermissionNameIsFocused = false"
                         @keyup.enter="addProjectPermission(newProjectPermissionName, newProjectPermissionType)"/>
                  <select class="form-select" v-model="newProjectPermissionType">
                    <option value="" disabled>Select access level...</option>
                    <option value="read">Read</option>
                    <option value="write">Write</option>
                  </select>
                  <button class="btn btn-accent"
                          @click="addProjectPermission(newProjectPermissionName, newProjectPermissionType)">
                    <span>Add &rsaquo;</span>
                  </button>
                </div>


                <div class="position-relative" v-if="newProjectPermissionNameIsFocused && filterAllProjects.length">
                  <ul class="clickable dropdown list-group position-absolute w-100">
                    <li class="list-group-item" v-for="project in filterAllProjects" :key="project"
                        @mousedown.prevent="setNewProjectPermissionName(project)">
                      {{ project }}
                    </li>
                  </ul>
                </div>


              </div>
            </div>
            <div v-if="loading" class="loading-spinner-large">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div v-else>
              <div class="bold mb-1 row">
                <div class="col-4">FSS Project Name</div>
                <div class="col-4">Access Level</div>
              </div>
              <div v-for="project in filterProjectPermissions" :key="project"
                   class="border-bottom py-2 row row-hover">
                <router-link class="col-4 clickable" :to="{ name: 'project', params: { project: project } }">
                  {{ project }}
                </router-link>
                <div class="col-4 fst-italic">{{ formatAccessLevel(this.projectPermissions[project]) }}</div>
                <a class="col-4 clickable text-end" @click="removeProjectPermission(project)">Remove</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.btn-accent {
  background: var(--accent);
  color: var(--bs-white);
}

.btn-accent:hover {
  background: var(--accent);
  color: var(--bs-white);
}

.dropdown {
  top: -1px;
}
</style>
