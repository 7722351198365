<template>
  <div class="vh-100 d-flex align-items-center justify-content-center">
    <div class="row">
      <div class="col text-center mx-auto width-75-max">
        <div class="border-0 card">
          <div class="card-body">
            <div class="h1 bold">Uh oh!</div>
            <div>We couldn't find that page. Please use the main menu at the top to navigate to another
              page.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.width-75-max {
  max-width: 75%;
}
</style>
