import {createRouter, createWebHistory} from 'vue-router';
import {authStore} from "../store/authStore";
import Error404View from "../views/Error404View.vue";
import GlobalPermissionsView from "../views/GlobalPermissionsView.vue";
import PermissionGroupsView from "../views/PermissionGroupsView.vue";
import PermissionGroupView from "../views/PermissionGroupView.vue";
import ProjectsView from "../views/ProjectsView.vue";
import ProjectView from "../views/ProjectView.vue";

const routes = [
    {path: `/:pathMatch(.*)*`, name: `error404`, component: Error404View},
    {path: `/`, redirect: {name: `projects`}},
    {path: `/project-permissions`, name: `projects`, component: ProjectsView},
    {path: `/project-permissions/:project/`, name: `project`, component: ProjectView, props: true},
    {path: `/permission-groups`, name: `permissionGroups`, component: PermissionGroupsView},
    {path: `/permission-groups/:group/`, name: `permissionGroup`, component: PermissionGroupView, props: true},
    {path: `/global-permissions`, name: `globalPermissions`, component: GlobalPermissionsView},
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    fetch(`${process.env.VUE_APP_AUTH_HOST}${process.env.VUE_APP_AUTH_STATUS_URL}?group=${process.env.VUE_APP_AUTH_STATUS_GROUP}`, {
        method: `GET`,
        credentials: `include`,
    })
        .then(response => response.json())
        .then(data => {
            if (!data.authenticated) {
                const currentUrl = encodeURIComponent(window.location.href);
                window.location.href = `${process.env.VUE_APP_AUTH_HOST}${process.env.VUE_APP_AUTH_LOGIN_URL}?target=${currentUrl}`;
            } else {
                if (data.group) {
                    authStore.authenticated = true;
                    next();
                } else {
                    authStore.authenticated = false;
                    alert(`You are not authorized to access this service.`);
                    next(false);
                }
            }
        })
        .catch(() => {
            authStore.authenticated = false;
            alert(`Could not authenticate user.`);
            //console.error(`Authentication check failed: ${error}`);
            next(false);
        });
});

export default router;