export const alertIfError = (response) => {
    if (response) {
        if (response.code !== 200) alert(response.message);
    } else alert(`An unknown error occurred`);
}
export const httpGet = (url, params = {}, includeCredentials = true) => {
    params = paramsToUrlParams(params);
    return fetch(
        `${url}?${params}`,
        {
            method: `GET`,
            credentials: includeCredentials ? `include` : `same-origin`,
        }
    ).then(
        response => response.json()
    )
}

export const httpPost = (url, params = {}, includeCredentials = true) => {
    return fetch(
        url,
        {
            method: `POST`,
            credentials: includeCredentials ? `include` : `same-origin`,
            headers: {
                'Content-Type': `application/json`
            },
            body: JSON.stringify(params)
        }
    ).then(
        response => response.json()
    )
}

export const paramsToUrlParams = (params) => {
    return Object.keys(params).map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
    }).join(`&`);
}